import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _556ad60a = () => interopDefault(import('../pages/AAHT.vue' /* webpackChunkName: "pages/AAHT" */))
const _7fb1590a = () => interopDefault(import('../pages/ABBM.vue' /* webpackChunkName: "pages/ABBM" */))
const _746d20bd = () => interopDefault(import('../pages/caregiving.vue' /* webpackChunkName: "pages/caregiving" */))
const _02f4c9a0 = () => interopDefault(import('../pages/companies/index.vue' /* webpackChunkName: "pages/companies/index" */))
const _3a341741 = () => interopDefault(import('../pages/demo.vue' /* webpackChunkName: "pages/demo" */))
const _4e196060 = () => interopDefault(import('../pages/fot25.vue' /* webpackChunkName: "pages/fot25" */))
const _32c23423 = () => interopDefault(import('../pages/lmr.vue' /* webpackChunkName: "pages/lmr" */))
const _5fa06601 = () => interopDefault(import('../pages/patient-insights.vue' /* webpackChunkName: "pages/patient-insights" */))
const _4dac1fdb = () => interopDefault(import('../pages/projects/index.vue' /* webpackChunkName: "pages/projects/index" */))
const _c8c6edfe = () => interopDefault(import('../pages/sign-on.vue' /* webpackChunkName: "pages/sign-on" */))
const _186ad213 = () => interopDefault(import('../pages/stm-school.vue' /* webpackChunkName: "pages/stm-school" */))
const _4b158843 = () => interopDefault(import('../pages/style.vue' /* webpackChunkName: "pages/style" */))
const _70d3b4b0 = () => interopDefault(import('../pages/theinfusion-referral-process.vue' /* webpackChunkName: "pages/theinfusion-referral-process" */))
const _11db8c52 = () => interopDefault(import('../pages/travel-to-learn.vue' /* webpackChunkName: "pages/travel-to-learn" */))
const _b5b47fc6 = () => interopDefault(import('../pages/users/index.vue' /* webpackChunkName: "pages/users/index" */))
const _24179fda = () => interopDefault(import('../pages/williamsburg-aaht.vue' /* webpackChunkName: "pages/williamsburg-aaht" */))
const _0a7af7f8 = () => interopDefault(import('../pages/ymcarichmond.vue' /* webpackChunkName: "pages/ymcarichmond" */))
const _5da5a5de = () => interopDefault(import('../pages/ymcarichmond2.vue' /* webpackChunkName: "pages/ymcarichmond2" */))
const _95363602 = () => interopDefault(import('../pages/companies/add.vue' /* webpackChunkName: "pages/companies/add" */))
const _5f2a986a = () => interopDefault(import('../pages/projects/add.vue' /* webpackChunkName: "pages/projects/add" */))
const _a14dca38 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _ac875f60 = () => interopDefault(import('../pages/companies/_company.vue' /* webpackChunkName: "pages/companies/_company" */))
const _04c86224 = () => interopDefault(import('../pages/projects/_project/index.vue' /* webpackChunkName: "pages/projects/_project/index" */))
const _5a868ab8 = () => interopDefault(import('../pages/users/_user/index.vue' /* webpackChunkName: "pages/users/_user/index" */))
const _3b869691 = () => interopDefault(import('../pages/users/_user/sign-up.vue' /* webpackChunkName: "pages/users/_user/sign-up" */))
const _48b17956 = () => interopDefault(import('../pages/projects/_project/participants/_participant.vue' /* webpackChunkName: "pages/projects/_project/participants/_participant" */))
const _07a40450 = () => interopDefault(import('../pages/projects/_project/stories/_story.vue' /* webpackChunkName: "pages/projects/_project/stories/_story" */))
const _196bdfe7 = () => interopDefault(import('../pages/users/_user/_project/index.vue' /* webpackChunkName: "pages/users/_user/_project/index" */))
const _6302d495 = () => interopDefault(import('../pages/users/_user/_project/journey.vue' /* webpackChunkName: "pages/users/_user/_project/journey" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/AAHT",
    component: _556ad60a,
    name: "AAHT"
  }, {
    path: "/ABBM",
    component: _7fb1590a,
    name: "ABBM"
  }, {
    path: "/caregiving",
    component: _746d20bd,
    name: "caregiving"
  }, {
    path: "/companies",
    component: _02f4c9a0,
    name: "companies"
  }, {
    path: "/demo",
    component: _3a341741,
    name: "demo"
  }, {
    path: "/fot25",
    component: _4e196060,
    name: "fot25"
  }, {
    path: "/lmr",
    component: _32c23423,
    name: "lmr"
  }, {
    path: "/patient-insights",
    component: _5fa06601,
    name: "patient-insights"
  }, {
    path: "/projects",
    component: _4dac1fdb,
    name: "projects"
  }, {
    path: "/sign-on",
    component: _c8c6edfe,
    name: "sign-on"
  }, {
    path: "/stm-school",
    component: _186ad213,
    name: "stm-school"
  }, {
    path: "/style",
    component: _4b158843,
    name: "style"
  }, {
    path: "/theinfusion-referral-process",
    component: _70d3b4b0,
    name: "theinfusion-referral-process"
  }, {
    path: "/travel-to-learn",
    component: _11db8c52,
    name: "travel-to-learn"
  }, {
    path: "/users",
    component: _b5b47fc6,
    name: "users"
  }, {
    path: "/williamsburg-aaht",
    component: _24179fda,
    name: "williamsburg-aaht"
  }, {
    path: "/ymcarichmond",
    component: _0a7af7f8,
    name: "ymcarichmond"
  }, {
    path: "/ymcarichmond2",
    component: _5da5a5de,
    name: "ymcarichmond2"
  }, {
    path: "/companies/add",
    component: _95363602,
    name: "companies-add"
  }, {
    path: "/projects/add",
    component: _5f2a986a,
    name: "projects-add"
  }, {
    path: "/",
    component: _a14dca38,
    name: "index"
  }, {
    path: "/companies/:company",
    component: _ac875f60,
    name: "companies-company"
  }, {
    path: "/projects/:project",
    component: _04c86224,
    name: "projects-project"
  }, {
    path: "/users/:user",
    component: _5a868ab8,
    name: "users-user"
  }, {
    path: "/users/:user/sign-up",
    component: _3b869691,
    name: "users-user-sign-up"
  }, {
    path: "/projects/:project/participants/:participant?",
    component: _48b17956,
    name: "projects-project-participants-participant"
  }, {
    path: "/projects/:project/stories/:story?",
    component: _07a40450,
    name: "projects-project-stories-story"
  }, {
    path: "/users/:user/:project",
    component: _196bdfe7,
    name: "users-user-project"
  }, {
    path: "/users/:user/:project/journey",
    component: _6302d495,
    name: "users-user-project-journey"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
